import { Box, Stack, Typography } from "@mui/material";
import React, { memo } from "react";

import { ImageSizeConstants } from "@/commons/dataset/constants/ImageSizeConstants";
import { ImgModel } from "types/imgModel";
import Link from "next/link";
import List from "@/commons/ui/components/list/List";
import PQImg from "@/commons/ui/components/pq-img/PQImg";
import PriceLabel from "@/commons/ui/components/price-label/PriceLabel";
import isEmpty from "lodash-es/isEmpty";
import styles from "./LeafletsListItemOffers.module.scss";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

interface Props {
  lazyLoad?: boolean;
  offers: any;
}
const LeafletsListItemOffers = ({ lazyLoad = true, offers = [] }: Props) => {
  const { pqConfs } = useSelectorPQGlobalThis();

  const getComposedTitle = (offer) => {
    const { product, brand, title, model } = offer;
    const titleComponents = [];

    if (product) {
      titleComponents.push(product.name);
    } else {
      titleComponents.push(title);
    }

    if (
      !isEmpty(model) &&
      !titleComponents[0].toLowerCase().includes(model.toLowerCase())
    ) {
      titleComponents.push(model);
    }

    if (
      brand &&
      !titleComponents[0].toLowerCase().includes(brand.name.toLowerCase())
    ) {
      titleComponents.push(brand.name);
    }

    return titleComponents.join(" ");
  };

  const getIcon = (offer) => {
    const title = getComposedTitle(offer);
    const { category } = offer;
    const imgModelProps: ImgModel = {
      img: category.flatIcon,
      webp: category.flatIconWebP,
      supportedFormat: category.flatIconWebPSupportedFormats,
      format: ImageSizeConstants.MEDIUM,
    };

    return (
      // Da sostituire con il nuovo componente PQNextImage
      <PQImg
        lazyLoad={lazyLoad}
        classNameContent={styles.offerImageImg}
        imgModel={imgModelProps}
        blurPlaceholder={`${pqConfs.dataAsset.url}/${category.flatIcon.thumb}`}
        altText={title}
      />
    );
  };

  return (
    <div className={styles.leafletsListItemOffers}>
      <List>
        {offers.map((offer) => {
          const { price, discount } = offer;

          const shouldRenderPrice = price && price != "";
          const shouldRenderDiscount = discount && discount != "";
          return (
            <Link href={offer.url} key={`leaflet-list-item-offer-${offer.id}`}>
              <a target={"_self"} rel={"nofollow"}>
                <div className={styles.offer}>
                  <div className={styles.offerImage}>{getIcon(offer)}</div>
                  <div className={styles.offerInfos}>
                    <div className={styles.infos}>
                      <Box
                        className={styles.infosPriceAndDiscount}
                        sx={{
                          marginBottom: { md: "5px" },
                        }}
                      >
                        {shouldRenderPrice && (
                          <div className={styles.infosPrice}>
                            <PriceLabel price={price} />
                          </div>
                        )}
                        {shouldRenderDiscount && (
                          <div
                            className={styles.infosDiscount}
                          >{`-${discount}%`}</div>
                        )}
                      </Box>
                      <div className={styles.infosTitle}>
                        {getComposedTitle(offer)}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          );
        })}
      </List>
    </div>
  );
};

export default memo(LeafletsListItemOffers);
