import { Children, useCallback, useMemo, ReactElement } from "react";
import styles from "./List.module.scss";

interface Props {
  horizontal?: boolean;
  inline?: boolean;
  classes?: any;
  onScroll?(): void;
  children?: ReactElement;
}

const List = ({
  horizontal = false,
  inline = false,
  classes,
  onScroll,
  children,
}: Props) => {
  const layout = horizontal ? "h" : inline ? "inline" : "v";

  const getClasses = useMemo(() => {
    let classesList = [styles[`${layout}-list`]];
    let classesItem = [styles[`${layout}-list__item`]];

    if (classes) {
      if (classes.list) {
        classesList = [...classesList, ...classes.list];
      }
      if (classes.item) {
        classesItem = [...classesItem, ...classes.item];
      }
    }

    return { list: classesList.join(" "), item: classesItem.join(" ") };
  }, [layout, classes]);

  const renderItems = useCallback(
    (classes) => {
      const childrens = Children.toArray(children);

      return childrens.map((child) => {
        return (
          <div key={(child as ReactElement).key} className={classes}>
            {child}
          </div>
        );
      });
    },
    [children],
  );

  const renderList = useCallback(() => {
    return (
      <div className={getClasses.list} onScroll={(_) => onScroll()}>
        {renderItems(getClasses.item)}
      </div>
    );
  }, [getClasses.item, getClasses.list, onScroll, renderItems]);

  return renderList();
};

export default List;
