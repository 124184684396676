import { Box, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";

import { ImageSizeConstants } from "@/commons/dataset/constants/ImageSizeConstants";
import LeafletCover from "@/commons/ui/components/leaflet-cover/LeafletCover";
import LeafletFooter from "../leaflet-footer/LeafletFooter";
import LeafletUtility from "@/commons/utilities/LeafletUtility";
import LeafletsListItemOffers from "@/commons/ui/components/leaflet-list-item-offers/LeafletsListItemOffers";
import LocationUtility from "@/commons/utilities/LocationUtility";
import { MainInfos } from "./MainInfo";
import PQNextImage from "../pq-next-image";
import PqImg from "@/commons/ui/components/pq-img/PQImg";
import styles from "./LeafletsListItem.module.scss";
import usePQTranslations from "@/commons/hooks/usePQTranslations";

interface Props {
  lazyLoad?: boolean;
  leaflet: any;
  position: any;
  direction?: "horizontal" | "vertical";
}

const LeafletsListItem = ({
  lazyLoad = true,
  leaflet,
  position,
  direction = "vertical",
}: Props) => {
  const t = usePQTranslations();
  const { retailer, offers } = leaflet;

  const realLocation = LocationUtility.getRealLocation();

  const distance = LeafletUtility.getDistanceWithMeasureUnit(
    leaflet,
    realLocation,
  );
  const getClasses = useMemo(() => {
    const classes = [styles.leafletsListItem];
    if (leaflet.isExpiring) classes.push(styles.leafletsListItemIsExpiring);
    if (leaflet.isPreview) classes.push(styles.leafletsListItemIsPreview);
    return classes.join(" ");
  }, [leaflet.isExpiring, leaflet.isPreview]);

  const detailsRetailer = useCallback(() => {
    if (!retailer.existsLogo) return;
    const imageModel = {
      img: retailer.logo,
      webp: retailer.logoWebP,
      supportedFormat: retailer.logoWebPSupportedFormats,
      format: ImageSizeConstants.THUMB,
    };
    return (
      <Stack direction="row" alignItems="center" gap={4}>
        <div className={styles.leafletsListItemForRetailerImageContainer}>
          {retailer.formats ? (
            <PQNextImage
              lazyLoad={lazyLoad}
              alt={retailer.name}
              width={120}
              height={71}
              webFormat="large"
              formats={retailer.formats}
            />
          ) : (
            // Da eliminare ...
            <PqImg
              lazyLoad={lazyLoad}
              imgModel={imageModel as any}
              altText={retailer.name}
              styleImg={{
                width: 120,
              }}
            />
          )}
        </div>

        <MainInfos leaflet={leaflet} retailer={retailer} />
      </Stack>
    );
  }, [lazyLoad, leaflet, retailer]);

  if (direction === "horizontal") {
    return (
      <Box className={styles.leafletsListItem}>
        {/* <Box width={{ xs: 171.5, lg: 212.5 }} height={{ xs: 215, lg: 266.25 }}> */}
        <Box
          width={{ xs: 162.5, sm: 171.5, lg: 212.5 }}
          height={{ xs: 215, lg: 266.25 }}
        >
          <LeafletCover
            lazyLoad={lazyLoad}
            leaflet={leaflet}
            position={position}
          />
        </Box>

        <Box marginTop={1}>
          <LeafletFooter
            title={leaflet.title}
            retailerName={retailer.name}
            endDate={leaflet.endDate}
            distance={distance}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" className={styles.mainInfosOnlyMobile}>
        <MainInfos leaflet={leaflet} retailer={retailer} />
      </Box>
      <Grid
        className={styles.leafletsListItemCover}
        container
        alignItems="start"
      >
        <Grid lg={4.5} xs={6} sm={4} md={5.5} item>
          <LeafletCover
            lazyLoad={lazyLoad}
            leaflet={leaflet}
            position={position}
          />
        </Grid>

        <Grid className={styles.gridContainer} item xs={6} md={6.5} sm={8}>
          <Box className={styles.mainInfosOnlyWeb}>{detailsRetailer()}</Box>
          <Box
            display={{ xs: "none", lg: "block" }}
            paddingTop={{
              md: 5,
            }}
            paddingBottom={2}
          >
            <Box className={styles.infos}>
              {offers?.length > 0 && (
                <Typography
                  color="#666"
                  textTransform="uppercase"
                  className={styles.leafletsListItemForRetailerAdditionalTitle}
                  sx={{ paddingLeft: "145px" }}
                  fontSize="16px"
                >
                  {t("offers.highlited")}
                </Typography>
              )}
            </Box>
          </Box>
          {offers?.length > 0 && (
            <Box>
              <LeafletsListItemOffers lazyLoad={lazyLoad} offers={offers} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeafletsListItem;
