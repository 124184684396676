import { Box, Stack, Typography } from "@mui/material";
import {
  OfferContainerCommonParamsFragment,
  Retailer,
} from "generated/graphql/types";
import { useCallback, useMemo } from "react";

import DateTimeUtility from "@/commons/utilities/DateTimeUtility";
import LeafletUtility from "@/commons/utilities/LeafletUtility";
import LocationUtility from "@/commons/utilities/LocationUtility";
import PQIcon from "../pq-icon/PQIcon";
import styles from "./LeafletsListItem.module.scss";

interface Props {
  retailer: Retailer;
  leaflet: OfferContainerCommonParamsFragment;
}

const MainInfos = ({ leaflet, retailer }: Props) => {
  const realLocation = LocationUtility.getRealLocation();

  //const title = `${retailer.name.toUpperCase()} - ${leaflet.title}`;
  const distance = LeafletUtility.getDistanceWithMeasureUnit(
    leaflet,
    realLocation,
  );

  const getDistanceString = useCallback(() => {
    const distance = LeafletUtility.getDistanceWithMeasureUnit(
      leaflet,
      realLocation,
    );

    const distanceStringComponents = [distance];

    if (leaflet.city && leaflet.city.name) {
      distanceStringComponents.push(`- ${leaflet.city.name}`);
    } else if (leaflet.store && leaflet.store.city && leaflet.store.city.name) {
      distanceStringComponents.push(`- ${leaflet.store.city.name}`);
    }

    // return distance;
    return distanceStringComponents.join(" ");
  }, [leaflet, realLocation]);

  const expiresColor = useMemo(
    () =>
      DateTimeUtility.isAboutToExpire(leaflet.endDate)
        ? "pqOrange"
        : "GrayText",
    [leaflet.endDate],
  );
  return (
    <Box className={styles.leafletsListItemForRetailerDetails}>
      <Stack direction="row">
        <Typography
          style={{ marginBottom: 5 }}
          className={styles.title}
          textTransform="uppercase"
          fontWeight="bold"
          lineHeight={1}
        >
          {retailer.name.toUpperCase()}
          {" - "}
          {leaflet.title}
        </Typography>
      </Stack>
      <Stack
        className={styles.detailsInfos}
        direction={{ xs: "row", lg: "column" }}
        paddingBottom={{ xs: 1, lg: 0 }}

        //justifyContent="center"
      >
        {!leaflet.isNational && distance && (
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            color={expiresColor}
            className={styles.infosDistance}
          >
            <PQIcon icon={"map-marker-alt"} />
            {getDistanceString()}
          </Stack>
        )}
        <Stack
          alignItems="center"
          direction="row"
          color={expiresColor}
          className={styles.infosExpiration}
          gap={1}
        >
          <PQIcon icon={"calendar"} />
          {DateTimeUtility.validsUntil(leaflet.endDate)}
        </Stack>
      </Stack>
    </Box>
  );
};

export { MainInfos };
