import React, { memo } from "react";

import { Box, Typography } from "@mui/material";
import styles from "./PriceLabel.module.scss";
import usePQTranslations from "@/commons/hooks/usePQTranslations";

const PriceLabel = ({ price, customFontSize = 12 }) => {
  const t = usePQTranslations();

  return (
    <div className={styles.priceLabel}>
      {/*   <Box
        sx={{ fontSize: `${customFontSize}` }}
        className={styles.priceLabelAmount}
      >
        {price}
      </Box> */}

      <Typography
        fontWeight="bold"
        fontSize={{ xs: 16, lg: 20 }}
        className={styles.priceLabelAmount}
      >
        {price}
      </Typography>
      <sup className={styles.priceLabelCurrency}>{t("currency.symbol")}</sup>
    </div>
  );
};

PriceLabel.defaultProps = {
  price: null,
};

export default memo(PriceLabel);
